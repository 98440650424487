/**
 * overlay to show while page is
 * loading the first time
 */
.booting {
  overflow-y: visible !important;
}

.boot-overlay,
.down-overlay {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  min-height: 100vh;
  min-width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  background: #262626 no-repeat fixed center;
  background-attachment: fixed;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease-in opacity, 0.5s ease-in visibility;
}

.boot-content,
.down-content {
  text-align: center;
  min-width: 100%;
  position: absolute;
  top: 50%;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #f5f5f5;
}

.booting>.boot-overlay {
  opacity: 1;
  visibility: visible;
  transition: 0.5s ease-in background-color;
}

/**
 * overlay to show if the connection
 * api result in errors or unecpected
 * return data
 */
.down-overlay {
  opacity: 1;
  z-index: 99999;
  visibility: visible;
  transition: 0.5s ease-in background-color;
}

@for $i from 1 through 100 {
  .fz-#{$i} {
    font-size: 1px * $i;
    font-family: Montserrat;
  }
}

@for $i from 1 through 1000 {
  .fw-#{$i} {
    font-weight: $i !important;
  }
}

@for $i from 1 through 1000 {
  .max-height-#{$i} {
    max-height: $i * 1px !important;
  }
}

@for $i from 1 through 1000 {
  .height-#{$i} {
    height: $i * 1px !important;
  }
}
